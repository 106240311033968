import React from 'react';

import packageJson from '../../package.json';

class Footer extends React.Component {

    /**
     * Custom constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            version: null
        };

        this.updateVersion = this.updateVersion.bind(this);
    }

    /**
     * ReactJS: Method is invoked immediately after a component is mounted.
     */
    componentDidMount() {

        // trigger continuous updates
        this.updateVersion();

        this.interval = setInterval(() => {
            this.updateVersion();
        }, 5000);
    }

    /**
     * Update version
     */
    updateVersion() {

        fetch("/version")
            .then(response => {
                if (200 === response.status) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then(version => {
                if (version == null) {
                    // set text
                    this.setState({ version: 'n. a.' });
                } else {
                    // build version text
                    var versionText = "v" + version.version

                    if (version.commitId) {
                        versionText += "-" + version.commitId;
                    }

                    if (version.buildTime) {
                        versionText += " · (" + version.buildTime + ")";
                    }

                    // set text
                    this.setState({ version: versionText });
                }
            });

    }


    /**
     * Return HTML snippet
     */
    render() {

        // final output
        return (
            <div className="container-fluid bg-dark text-light px-3 py-2">
                <small><small>
                    <div className="row row-cols-2">
                        <div className="col">
                            ricebean.net Sales Platform
                        </div>
                        <div className="col text-end">
                            &copy; All Rights Reserved
                        </div>
                    </div>
                    <div className="row row-cols-2 pt-1">
                        <div className="col text-nowrap">
                            {this.state.version}
                        </div>
                        <div className="col text-end">
                            Client: v{packageJson.version}
                        </div>
                    </div>
                </small></small>
            </div>
        );
    }
}

export default Footer;
