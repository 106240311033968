import React from 'react';


import logo from '../assets/ricebean-net.png';

class Header extends React.Component {

    /**
     * Return HTML snippet
     */
    render() {

        // final output
        return (
            <div>
                <nav className="navbar px-4 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="navbar-brand"><img className="logo" src={logo} style={{ height: 40 }} alt="ricebean.net" /></div>
                    </div>
                </nav>
                <nav className="navbar subtitles px-4 border-bottom shadow-sm">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                            <span style={{ fontSize: '1.6em', fontWeight: 400, color: '#B72B2A' }}>Sales Platform</span>
                        </div>
                        <div>
                            {
                                this.props.user ?
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-link p-0" id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src={this.props.user.picture} title={this.props.user.name} className="rounded-circle" style={{ height: 38 }} alt={this.props.user.name} />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownUser">
                                            <li><button className="dropdown-item" onClick={this.props.logout}>Logout</button></li>
                                        </div>
                                    </div>
                                    :
                                    <button type="button" className="btn btn-outline-secondary" onClick={this.props.signIn} >Login</button>
                            }
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Header;
