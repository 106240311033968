import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as url from 'url';

import Header from './common/Header'
import Footer from './common/Footer'

const Dashboard = lazy(() => import('./main/dashboard/Dashboard'));
const AtlassianTransactionOverview = lazy(() => import('./main/atlassian/transaction/AtlassianTransactionOverview'))
const AtlassianTransactionDetail = lazy(() => import('./main/atlassian/transaction/AtlassianTransactionDetail'))
const AtlassianLicenseOverview = lazy(() => import('./main/atlassian/license/AtlassianLicenseOverview'))
const AtlassianLicenseDetail = lazy(() => import('./main/atlassian/license/AtlassianLicenseDetail'))
const AtlassianAddonOverview = lazy(() => import('./main/atlassian/addon/AtlassianAddonOverview'))
const AtlassianAddOnAnalytics = lazy(() => import('./main/atlassian/addon/analytics/AtlassianAddOnAnalytics'))

const PartnerOverview = lazy(() => import('./main/partner/PartnerOverview'))
const PartnerDetail = lazy(() => import('./main/partner/PartnerDetail'))

const CustomerOverview = lazy(() => import('./main/customer/CustomerOverview'))
const CustomerDetail = lazy(() => import('./main/customer/CustomerDetail'))

const ControlPanel = lazy(() => import('./main/system/ControlPanel'))

class App extends React.Component {

    /**
     * Custom constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            token: null,

            transactions: [],
            licenses: [],
            customers: [],
            partners: [],
            addons: []
        };

        this.loadToken = this.loadToken.bind(this);
        this.loadUserDetails = this.loadUserDetails.bind(this);
        this.signIn = this.signIn.bind(this);
        this.logout = this.logout.bind(this);

        this.loadData = this.loadData.bind(this);
        this.loadTransactions = this.loadTransactions.bind(this);
        this.loadLicenses = this.loadLicenses.bind(this);
        this.loadCustomers = this.loadCustomers.bind(this);
        this.loadPartners = this.loadPartners.bind(this);
        this.loadAddons = this.loadAddons.bind(this);

        this.saveCustomer = this.saveCustomer.bind(this);
        this.savePartner = this.savePartner.bind(this);
    }

    /**
     * ReactJS: Method is invoked immediately after a component is mounted.
     */
    componentDidMount() {

        // get code from url
        const queryParams = url.parse(window.location.href, true).query;

        // check if there are url params
        if (queryParams.code !== undefined) {

            // load token
            this.loadToken(queryParams);

        } else if (localStorage.getItem('TOKEN')) {

            // load user
            this.loadUserDetails();

            // load data
            this.loadData();
        }
    }

    /**
     * Load data.
     */
    loadData() {

        // get token
        var token = JSON.parse(localStorage.getItem('TOKEN'));

        // load data
        this.loadTransactions(token);
        this.loadLicenses(token);
        this.loadCustomers(token);
        this.loadPartners(token);
        this.loadAddons(token);
    }

    /**
     * Load addons
     */
    loadAddons(token) {

        // set headers
        var headers = {
            'Authorization': 'Bearer ' + token.id_token
        }

        // load addons
        fetch("/v0/atlassian/addon/", {
            headers: headers
        })
            .then(response => {
                if (200 === response.status) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then(addons => {

                // sort
                addons.sort((a, b) => {
                    if (a.volume === b.volume) {
                        return (a.totalInstalls < b.totalInstalls) ? 1 : -1;
                    }

                    return (a.volume < b.volume) ? 1 : -1;
                })

                // set state
                this.setState({
                    addons: addons
                });
            });
    }

    /**
     * Load partners
     */
    loadPartners(token) {
        const start = Date.now();

        // set headers
        var headers = {
            'Authorization': 'Bearer ' + token.id_token
        }

        // load transactoins
        fetch("/v0/partner/", {
            headers: headers
        })
            .then(response => {
                if (200 === response.status) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then(partners => {
                console.log(`${partners.length} partners have been loaded. (${Date.now() - start} ms)`);

                this.setState({
                    partners: partners
                });
            });
    }

    /**
     * Save a partner object
     * @param {*} partner 
     */
    savePartner(partner) {
        var token = JSON.parse(localStorage.getItem('TOKEN'));

        // set headers
        var headers = {
            'Authorization': 'Bearer ' + token.id_token,
            'Content-Type': 'application/json'
        }

        // load transactoins
        fetch("/v0/partner/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(partner)
        })
            .then(response => {
                if (200 === response.status) {
                    this.loadPartners(token);
                } else {
                    throw new Error("Error assign to partner.");
                }
            })
    }

    /**
     * Save a customer object
     * @param {*} customer 
     */
    saveCustomer(customer) {
        var token = JSON.parse(localStorage.getItem('TOKEN'));

        // set headers
        var headers = {
            'Authorization': 'Bearer ' + token.id_token,
            'Content-Type': 'application/json'
        }

        // load transactoins
        fetch("/v0/customer/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(customer)
        })
            .then(response => {
                if (200 === response.status) {
                    this.loadCustomers(token);
                } else {
                    throw new Error("Error assign to customer.");
                }
            })
    }

    /**
     * Load customers
     */
    loadCustomers(token) {
        const start = Date.now();

        // set headers
        var headers = {
            'Authorization': 'Bearer ' + token.id_token
        }

        // load transactoins
        fetch("/v0/customer/", {
            headers: headers
        })
            .then(response => {
                if (200 === response.status) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then(customers => {
                console.log(`${customers.length} customers have been loaded. (${Date.now() - start} ms)`);

                this.setState({
                    customers: customers
                });
            });
    }

    /**
     * Load licenses
     */
    loadLicenses(token) {
        const start = Date.now();

        // set headers
        var headers = {
            'Authorization': 'Bearer ' + token.id_token
        }

        // load licenses
        fetch("/v0/atlassian/license/", {
            headers: headers
        })
            .then(response => {
                if (200 === response.status) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then(licenses => {

                // sort by maintenance start date descending
                licenses.sort((a, b) => {
                    return (a.maintenanceStartDate < b.maintenanceStartDate) ? 1 : -1;
                });

                // set state
                console.log(`${licenses.length} licenses have been loaded. (${Date.now() - start} ms)`);

                this.setState({
                    licenses: licenses
                });
            });
    }

    /**
     * Load Transactions
     */
    loadTransactions(token) {
        const start = Date.now();

        // set headers
        var headers = {
            'Authorization': 'Bearer ' + token.id_token
        }

        // load transactions
        fetch("/v0/atlassian/transaction/", {
            headers: headers
        })
            .then(response => {
                if (200 === response.status) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then(transactions => {
                // sort by maintenance start date descending
                transactions.sort((a, b) => {
                    return (a.saleDate < b.saleDate) ? 1 : -1;
                });

                console.log(`${transactions.length} transactions have been loaded. (${Date.now() - start} ms)`);

                this.setState({
                    transactions: transactions
                });
            });
    }

    /**
     * Load the oauth2 token.
     */
    loadToken(queryParams) {

        // set params
        const CLIENT_ID = '575741560670-jgbsc7a6tb74f7bo0nsj2j62g3o4g227.apps.googleusercontent.com';
        const CLIENT_SECRET = 'MjHHDWGmFFqBzt1HFuN1eDoD';
        const REDIRECT_URI = window.location.protocol + "//" + window.location.host;

        // build request url
        var url = 'https://oauth2.googleapis.com/token';
        url = url + "?code=" + queryParams['code'];
        url = url + "&redirect_uri=" + REDIRECT_URI;
        url = url + "&client_id=" + CLIENT_ID;
        url = url + "&client_secret=" + CLIENT_SECRET;
        url = url + "&grant_type=authorization_code";

        fetch(url, { method: "POST" })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(response.status)
                }

                return response.json();
            })
            .then(token => {

                // save token in local storage
                localStorage.setItem('TOKEN', JSON.stringify(token));

                // redirect
                window.location.href = window.location.protocol + "//" + window.location.host + queryParams['state'];

            })
            .catch((error) => {

                // remove token
                localStorage.removeItem('TOKEN');

                // log error
                console.error('Error:', error);
            });
    }

    /**
     * Load the use detals.
     */
    loadUserDetails() {

        // get access token
        var accessToken = JSON.parse(localStorage.getItem('TOKEN')).access_token;

        // load user details
        fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=' + accessToken)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(response.status)
                }

                return response.json();
            })
            .then(userinfo => {

                // create user object
                var user = {
                    family_name: userinfo.family_name,
                    given_name: userinfo.given_name,
                    name: userinfo.name,
                    picture: userinfo.picture,
                    locale: userinfo.locale
                };

                // save user object
                this.setState({
                    user: user
                });
            })
            .catch((error) => {
                this.setState({
                    user: null
                });

                console.error('Error:', error);
            });
    }

    /**
     * SignIn with your google account.
     */
    signIn() {

        // build authorizeUrl
        var url = 'https://accounts.google.com/o/oauth2/v2/auth';
        url = url + "?client_id=575741560670-jgbsc7a6tb74f7bo0nsj2j62g3o4g227.apps.googleusercontent.com";
        url = url + "&redirect_uri=" + window.location.protocol + "//" + window.location.host;
        url = url + "&scope=https://www.googleapis.com/auth/userinfo.profile openid";
        url = url + "&access_type=online";
        url = url + "&include_granted_scopes=true";
        url = url + "&response_type=code";
        url = url + "&state=" + window.location.pathname;

        // redirect
        window.location.href = url;
    }

    /**
     * Log out.
     */
    logout() {

        // logout
        fetch('https://accounts.google.com/o/oauth2/revoke?token=' + this.state.accessToken)
            .then(() => {
                localStorage.removeItem('TOKEN');

                this.setState({
                    user: null
                });
            });
    }

    /**
     * Return HTML snippet
     */
    render() {



        // loading page
        const loading = (
            <div>

                {/* breadcrumbs */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-title">Location:</li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>

                { /* content */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            Loading...
                        </div>
                    </div>
                </div>
            </div >
        );

        // return main page
        return (
            <div className="d-flex flex-column vh-100">
                <div className="mb-3">
                    <Header user={this.state.user} signIn={this.signIn} logout={this.logout}></Header>
                </div>
                <div className="flex-fill">
                    {
                        this.state.user ?
                            <Router>
                                <Suspense fallback={loading}>
                                    <Switch>
                                        <Route exact path="/" >
                                            <Dashboard transactions={this.state.transactions} licenses={this.state.licenses} customers={this.state.customers}
                                                partners={this.state.partners} addons={this.state.addons} />
                                        </Route>

                                        <Route exact path="/atlassian/transaction" render={() => (
                                            <AtlassianTransactionOverview transactions={this.state.transactions} customers={this.state.customers}
                                                partners={this.state.partners} saveCustomer={this.saveCustomer} savePartner={this.savePartner} />
                                        )} />

                                        <Route exact path="/atlassian/transaction/:id" render={(props) => (
                                            <AtlassianTransactionDetail transaction={this.state.transactions.find(transaction => transaction.transactionId === props.match.params.id)}
                                                customers={this.state.customers} partners={this.state.partners} />
                                        )} />

                                        <Route exact path="/atlassian/license" render={() => (
                                            <AtlassianLicenseOverview licenses={this.state.licenses} />
                                        )} />

                                        <Route exact path="/atlassian/license/:id" render={(props) => (
                                            <AtlassianLicenseDetail license={this.state.licenses.find(license => license.licenseId === props.match.params.id)}
                                                customers={this.state.customers} />
                                        )} />

                                        <Route exact path="/atlassian/addon/analytics" render={() => (
                                            <AtlassianAddOnAnalytics transactions={this.state.transactions} licenses={this.state.licenses} customers={this.state.customers}
                                                partners={this.state.partners} addons={this.state.addons} />
                                        )} />

                                        <Route exact path="/atlassian/addon" render={() => (
                                            <AtlassianAddonOverview addons={this.state.addons} />
                                        )} />

                                        <Route exact path="/customer" render={() => (
                                            <CustomerOverview customers={this.state.customers} transactions={this.state.transactions} />
                                        )} />

                                        <Route exact path="/customer/:id" render={(props) => (
                                            <CustomerDetail customer={this.state.customers.find(customer => customer.id === props.match.params.id)}
                                                partners={this.state.partners} transactions={this.state.transactions} />
                                        )} />

                                        <Route exact path="/partner" render={() => (
                                            <PartnerOverview partners={this.state.partners} transactions={this.state.transactions} />
                                        )} />

                                        <Route exact path="/partner/:id" render={(props) => (
                                            <PartnerDetail partner={this.state.partners.find(partner => partner.id === props.match.params.id)}
                                                customers={this.state.customers} transactions={this.state.transactions} id={props.match.params.id} />
                                        )} />

                                        <Route exact path="/system/control-panel" render={() => (
                                            <ControlPanel />
                                        )} />
                                    </Switch>
                                </Suspense>
                            </Router>
                            : <div></div>
                    }
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        );

    }

}

export default App;
